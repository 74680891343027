<template>
  <div :class="['preferential-copy', { 'preferential-copy-inline': isInlineBlock }]">
    <div
      v-if="randomDiscountText"
      class="preferential-container"
    >
      <div class="preferential-copy-block">
        <span
          class="preferential-text"
          :style="{ color: locals.IS_RW && '#FC4070' }"
        >
          {{ randomDiscountText }}
        </span>
      </div>
    </div>
    <template v-else>
      <div
        v-if="paymentText"
        class="preferential-container"
      >
        <div class="preferential-copy-block">
          <span
            class="preferential-text"
            :style="{ color: locals.IS_RW && '#FC4070' }"
          >
            {{ paymentText }}
            <sui_icon_doubt_16px_2
              v-if="isHadDoubt"
              class="icon"
              size="16px"
              :is-rotate="locals.GB_cssRight"
            />
          </span>
        </div>
        <span
          v-if="preferentialText"
          class="addicon"
        >
          +
        </span>
      </div>

      <div
        v-if="preferentialText"
        class="preferential-copy-block"
      >
        <span
          class="preferential-text"
          :style="{ color: locals.IS_RW && '#FC4070' }"
        >
          {{ preferentialText }}
          <sui_icon_doubt_16px_2
            v-if="isHadDoubt"
            class="icon"
            size="16px"
            :is-rotate="locals.GB_cssRight"
          />
        </span>
      </div>
    </template>
    <!-- 联名卡利诱点 -->
    <div
      v-if="coBrandCardText"
      v-expose="{
        id:'expose_storicard_points:simple',
        data:{
          points_position: 'points_details'
        }
      }"
      class="preferential-copy-block"
    >
      <span
        class="preferential-text"
        :style="{ color: locals.IS_RW && '#FC4070' }"
      >
        <span v-html="coBrandCardText"></span>
        <sui_icon_doubt_16px_2
          class="icon"
          size="16px"
          :is-rotate="locals.GB_cssRight"
          @click.stop="handlePaymentOptionsOper('co-brand-card-article')"
        />
      </span>
    </div>
  </div>
</template>

<script>
/**
 * 	下单页卡bin优惠文案
 */
import { mapState } from 'vuex'
import { daEventCenter as daEventCenterV2 } from '@shein-aidc/basis-sa-event-center'
import { sui_icon_doubt_16px_2 } from '@shein-aidc/icon-vue2'

export default {
  components: {
    sui_icon_doubt_16px_2
  },
  props: {
    randomDiscountText: {
      type: String,
      default: ''
    },
    preferentialText: {
      type: String,
      default: ''
    },
    paymentText: {
      type: String,
      default: ''
    },
    coBrandCardText: {
      type: String,
      default: ''
    },
    isHadDoubt: {
      type: Boolean,
      default: false
    },
    isInlineBlock: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('orderDetail', [
      'locals'
    ]),
  },
  methods: {
    handlePaymentOptionsOper(type = '', data = {}) {
      this.$emit('payment-options-oper', { type, data })
      // 点击埋点
      daEventCenterV2.triggerNotice({
        id: 'click_storicard_points:simple',
        data: {
          points_position: 'points_details'
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.preferential-copy {
  // display: flex;
  .preferential-container {
    display: flex;
    .addicon {
      margin-left: 4px;
      // color: #C44A01;
    }
  }
  .preferential-copy-block {
    box-sizing: border-box;
    padding: 4/75rem 8/75rem;
    background: #fff6f3;
    color: @sui_color_promo_dark;
    .border-dpr(border, 1px, @sui_color_prompt_unusual);
    .flexbox();
    margin-bottom: 5px;
    width: fit-content;
    max-width: 100%;

    .preferential-text {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      color: #c44a01;
      line-height: 1;
      display: inline-block;
      vertical-align: middle;
      word-wrap: break-word;
      width: 100%;
    }

    .icon {
      font-size: 12px;
      color: #959595;
      margin-left: 4px;
    }
  }
}

.preferential-copy-inline {
  display: inline-block;
}
</style>
