<template>
  <div>
    <div
      v-show="showDetailFooter"
      class="macc-fixed-btn macc-pad-wrapper order-detail-footer detail-footer-text-right detail-footer-z-fixedcheckout"
      :class="{ 'detail-footer-flex_layout': isShowApplepay }"
      :data-billno="billno"
    >
      <ClientOnly>
        <PayNowSwiper
          v-if="orderDetailPromotionTest == 'on1' && payNowLabelList.length"
          v-expose="{ id: '1-19-1-138', data: analysisData }"
          :language="language"
          :pay-now-label-list="payNowLabelList"
        />
      </ClientOnly>
      <s-button
        v-if="fromRecycle == 'recycleOrderList'"
        class="order-detail-footer__btn"
        :type="['H56PX']"
        @click="handleReduction(orderInfo.billno)"
      >
        {{ language.SHEIN_KEY_PWA_18261 }}
      </s-button>
      <!-- paypal-ga 的sdk初始化按钮 这里不能使用v-if -->
      <div
        v-show="paypalBtnShow"
        key="paypal_pay_now"
        style="max-width: 1.8rem; display: inline-block; margin: 0.17rem 0"
      >
        <div id="paypal-vault-detail-button"></div>
      </div>
      <!-- paypal-bnpl 的sdk初始化按钮 这里不能使用v-if -->
      <div
        v-show="paypalBnplBtnShow"
        style="max-width: 1.8rem; display: inline-block; margin: 0.17rem 0"
      >
        <div id="paypal-bnpl-detail-button"></div>
      </div>
      <template v-for="name in orderOperationSortedButtons">
        <!-- upload report -->
        <s-button
          v-if="'upload_report' === name"
          :key="`oper_button_${name}`"
          :type="['H56PX']"
          class="order-detail-footer__btn"
          :class="{ 'report-disabled': orderInfo.uploadTryStatus.status == 2 }"
          @click="toEditReport(orderInfo.uploadTryStatus, 'button')"
        >
          {{ language.SHEIN_KEY_PWA_16228 }}
        </s-button>
        <!-- confirm_delivery -->
        <s-button
          v-if="name == 'confirm_delivery'"
          :key="`oper_button_${name}`"
          :class="{ 'over-time': timeTips }"
          class="order-detail-footer__btn"
          :disabled="confirmOver"
          style="margin-bottom: 0.1rem"
          :type="['H56PX']"
          @click="confirmDeliverAlterFun"
        >
          {{ language.SHEIN_KEY_PWA_14854 }}
        </s-button>
        <!-- view_invoices -->
        <s-button
          v-if="name === 'view_invoices'"
          :key="`oper_button_${name}`"
          class="order-detail-footer__btn"
          style="margin-bottom: 0.1rem"
          :type="['H56PX']"
          @click="navToViewInvoices(orderInfo.billno)"
        >
          {{ language.SHEIN_KEY_PWA_18679 }}
        </s-button>
        <!-- Payment Receipt -->
        <s-button
          v-if="name === 'payment_receipt'"
          :key="`oper_button_${name}`"
          class="order-detail-footer__btn"
          style="margin-bottom: 0.1rem"
          :type="['H56PX']"
          @click="toPaymentReceipt"
        >
          {{ language.SHEIN_KEY_PWA_34245 }}
        </s-button>
        <!-- Review -->
        <s-button
          v-if="name == 'review'"
          :key="name"
          class="order-detail-footer__btn comment-btn-box"
          :class="{ 'hide-points-tip': isRiskComment }"
          name="review"
          :type="['H56PX']"
          @click="navToReview()"
        >
          {{ language.SHEIN_KEY_PWA_15162 }}
          <div
            class="comment-btn-op"
          >
            <div
              :ref="`${orderInfo.billno}-points-tip`"
              class="points-tip-bottom"
              @click.stop="handlePointsTipShow(`${orderInfo.billno}-points-tip`)"
            >
              <i class="iconfont icon-close"></i>
              {{ language.SHEIN_KEY_PWA_16291 }}
            </div>
            <span
              :ref="`${orderInfo.billno}-points-tip-arrow`"
              class="points-tip-arrow"
            ></span>
          </div>
        </s-button>
        <!-- MyReview -->
        <s-button
          v-if="name == 'my_review'"
          :key="name"
          class="order-detail-footer__btn"
          :type="['H56PX']"
          @click="navToMyReview(orderInfo.billno)"
        >
          {{ language.SHEIN_KEY_PWA_16290 }}
        </s-button>
        <!-- pay_now,不可支付,可支付去掉类disabld -->
        <template v-if="name == 'pay_now'">
          <pay-now-button
            v-if="orderInfo.payment_type == 2 && ( orderInfo.pay_code_url || orderInfo.pay_url)"
            :key="`oper_button_${name}`"
            :type="['H56PX']"
            :count-down="countDown"
            :pay-now-label-list="payNowLabelList"
            :order-detail-promotion-test="orderDetailPromotionTest"
            :analysis-data="analysisData"
            class="order-detail-footer__btn"
            @click.stop="payNowCommonEvt({ payType: 1 })"
          >
            {{ language.SHEIN_KEY_PWA_24198 }}
          </pay-now-button>
          <pay-now-button
            v-else-if="paypalVenmoBtnShow && ['PayPal-Venmo'].includes(orderInfo.payment_method)"
            :key="`oper_button_${name}`"
            :type="['H56PX']"
            :count-down="countDown"
            :pay-now-label-list="payNowLabelList"
            :order-detail-promotion-test="orderDetailPromotionTest"
            :analysis-data="analysisData"
            class="footer-venmo-btn"
            DA-view-type="ordinary"
            DA-type="syncClick"
            @click.stop="payNowCommonEvt({ payType: 2, confirmPayType: 'PayNow' })"
          >
            <img
              :src="locals.IMG_LINK['blue-venmo-button']"
              class="venmo-btn-img"
            />
          </pay-now-button>
          <template
            v-else
          >
            <ApplePayBtn
              v-if="isShowApplepay"
              :key="`oper_button_${name}`"
              class="apple-pay-btn_box"
              DA-view-type="ordinary"
              DA-type="syncClick"
              :analysis-data="analysisData"
              :props-style="{
                width: 'auto',
                height: '28px',
                direction: 'ltr',
                display: 'inline-block'
              }"
              @handleBtnClick="payNowCommonEvt({ payType: 2, confirmPayType: 'PayNow' })"
            />
            <pay-now-button
              v-else
              :key="`oper_button_${name}`"
              :type="['H56PX']"
              :count-down="countDown"
              :pay-now-label-list="payNowLabelList"
              :order-detail-promotion-test="orderDetailPromotionTest"
              :analysis-data="analysisData"
              class="order-detail-footer__btn"
              DA-view-type="ordinary"
              DA-type="syncClick"
              @click.stop="payNowCommonEvt({ payType: 2, confirmPayType: 'PayNow' })"
            >
              {{ payNowText }}
            </pay-now-button>
          </template>
        </template>

        <!-- verify,in站不能在ar站短信验证 -->
        <template v-if="name == 'verify'">
          <s-button
            v-if="getCodOrderFrom(locals.lang).indexOf(orderInfo.site_from) > -1"
            :key="name"
            DA-view-type="ordinary"
            DA-type="syncClick"
            :type="['H56PX']"
            class="order-detail-footer__btn"
            @click.stop="payNowCommonEvt({ payType: 2, confirmPayType: 'VerifyNow' })"
          >
            {{ language.SHEIN_KEY_PWA_30858 }}
          </s-button>
          <s-button
            v-else
            :key="name"
            class="order-detail-footer__btn"
            disabled
            :type="['H56PX']"
          >
            {{ language.SHEIN_KEY_PWA_30858 }}
          </s-button>
        </template>

        <!-- track -->
        <s-button
          v-if="name == 'track'"
          :key="name"
          class="order-detail-footer__btn j-track-btn"
          DA-type="syncClick"
          DA-view-type="ordinary"
          DA-sa-name="track"
          :type="['H56PX']"
          @click="handelTrackBtnClick"
        >
          <span v-html="language.SHEIN_KEY_PWA_15247"></span>
        </s-button>
        <!-- Return Item -->
        <s-button
          v-if="name == 'return_item'"
          :key="name"
          v-expose="{ id: '1-19-1-157', data: { position: defaultMoreOpen && !!isHideReturnItemBtn ? 'moreopen' : !!isHideReturnItemBtn ? 'more' : 'outside' } }"
          v-tap="{ id: '1-19-1-155', data: { position: defaultMoreOpen && !!isHideReturnItemBtn ? 'moreopen' : !!isHideReturnItemBtn ? 'more' : 'outside' } }"
          da-sa-id="1-19-1-157"
          :da-sa-param="JSON.stringify({ position: defaultMoreOpen ? 'moreopen' : 'more' })"
          name="returnItem"
          class="j-return-btn j-return-item-btn ga-return-item order-detail-footer__btn"
          :data-status="ordersStatus[orderInfo.orderStatus]"
          :type="['H56PX']"
          @click="returnItemEvt(orderInfo)"
        >
          {{ language.SHEIN_KEY_PWA_33481 }}
        </s-button>
        <!-- cancel order -->
        <s-button
          v-if="name == 'cancel_order'"
          :key="name"
          class="order-detail-footer__btn"
          DA-view-type="ordinary"
          DA-type="syncClick"
          DA-sa-name="unpaid_cancel_order"
          :DA-sa-param="JSON.stringify({ order_id: orderInfo.billno })"
          :type="['H56PX']"
          @click="handleCancelOrder"
        >
          {{ language.SHEIN_KEY_PWA_15714 }}
        </s-button>
        <!-- cancel item -->
        <s-button
          v-if="name == 'cancel_item'"
          :key="name"
          class="order-detail-footer__btn"
          :class="{
            'opacity-item': overFrontCondition > 1 || overCancelItemCondition || disabledCancelItem
          }"
          :type="['H56PX']"
          @click="cancelItemShow"
        >
          {{ showRefundNew ? language.SHEIN_KEY_PWA_15498: language.SHEIN_KEY_PWA_15873 }}
        </s-button>
        <!-- 已短信验证未发货的单件撤销  Revoke item-->
        <s-button
          v-if="name == 'revoke_item'"
          :key="name"
          :class="{
            'opacity-item': overFrontCondition > 1 || overCancelItemCondition || disabledCodOrderPartRefund
          }"
          class="order-detail-footer__btn"
          :type="['H56PX']"
          @click="revokeItemEvt"
        >
          {{ language.SHEIN_KEY_PWA_15498 }}
        </s-button>
        <!-- Repurchase,回购 -->
        <s-button
          v-if="name == 'repurchase'"
          :key="name"
          v-expose="{ id: '1-19-1-153', data: repurchaseAnalysisData }"
          da-sa-id="1-19-1-153"
          :da-sa-param="JSON.stringify(repurchaseAnalysisData)"
          :type="['H56PX']"
          class="order-detail-footer__btn repurchase-btn"
          @click="handleSave"
        >
          {{ language.SHEIN_KEY_PWA_16211 }}
          <RepurchaseLabel 
            v-if="showOrderRepurchase && orderInfo.repurchase_lure_info && orderInfo.repurchase_lure_info.tip"
            :repurchase-info="orderInfo.repurchase_lure_info"
            :locals="locals"
          />
        </s-button>
        <!-- one click pay -->
        <s-button
          v-if="name == 'one_click_pay'"
          :key="`oper_button_${name}`"
          v-expose="{ id: '1-19-1-90', data: { order_no: orderInfo.merge_buy_billno } }"
          :type="['H56PX', 'primary']"
          class="order-detail-footer__btn one_click_pay-detail"
          @click="toRecommendEvt"
        >
          {{ language.SHEIN_KEY_PWA_23667 }}
          <div class="free_tips">
            <div class="free_text">
              <i class="suiiconfont sui_icon_free_shipping_12px icon"></i>
              {{ language.SHEIN_KEY_PWA_23668 }}
            </div>
          </div>
        </s-button>
        <s-button
          v-if="name === 'urge_shipment'"
          :key="`oper_button_${name}`"
          v-expose="{ id: '1-19-1-120', data: { order_no: orderInfo.billno } }"
          :type="['H56PX']"
          class="order-detail-footer__btn j-track-btn"
          @click="urgeShipment"
        >
          {{ language.SHEIN_KEY_PWA_26377 }}
        </s-button>
        <!-- 催派 -->
        <s-button
          v-if="name == 'urge_delivery'"
          :key="`oper_button_${name}`"
          v-expose="{ id: '1-19-1-122', data: { order_no: orderInfo.billno } }"
          v-tap="{ id: '1-19-1-123', data: { order_no: orderInfo.billno } }"
          :type="['H56PX']"
          class="order-detail-footer__btn"
          @click="showUrgeDelivery"
        >
          {{ language.SHEIN_KEY_PWA_26632 }}
        </s-button>
        <!-- Exchange -->
        <s-button
          v-if="name == 'exchange'"
          :key="`oper_button_${name}`"
          v-expose="{
            id: '1-19-1-135',
            data: { order_no: orderInfo.billno, style: exchangeDisabled ? 'gray' : 'normal' }
          }"
          v-tap="{
            id: '1-19-1-136',
            data: { order_no: orderInfo.billno, style: exchangeDisabled ? 'gray' : 'normal' }
          }"
          :type="['H56PX']"
          class="order-detail-footer__btn"
          :class="{ 'opacity-item': exchangeDisabled }"
          DA-sa-id="1-19-1-135"
          :DA-sa-param="
            JSON.stringify({ order_no: orderInfo.billno, style: exchangeDisabled ? 'gray' : 'normal' })
          "
          @click="handleExchangeOperation"
        >
          {{ language.SHEIN_KEY_PWA_29069 }}
        </s-button>
        <s-button
          v-if="name == 'urgent_pickup'"
          :key="`oper_button_${name}`"
          :type="['H56PX']"
          class="order-detail-footer__btn"
          @click="urgeReturnEvt"
        >
          {{ language.SHEIN_KEY_PWA_31578 }}
        </s-button>
        <!-- 新编辑按钮 -->
        <EditAddressBtnNew
          v-if="'edit_address' == name"
          :key="`oper_button_${name}`"
          :editAddressNewConfigList="editAddressNewConfigList"
          :order="newOrderInfo"
          :language="language"
          :newEditAddressOptions="newEditAddressOptions"
          @showEditAddressDialog="showEditAddressDialogEvt"
        />
        <s-button
          v-if="name == 'resume_shipment'"
          :key="`oper_button_${name}`"
          v-expose="{ id: '1-19-1-159', data: { order_id: orderInfo.billno } }"
          v-tap="{ id: '1-19-1-160', data: { order_id: orderInfo.billno } }"
          :type="['H56PX']"
          class="order-detail-footer__btn resume-shipment-btn"
          @click="resumeShipment"
        >
          {{ language.SHEIN_KEY_PWA_34358 }}
          <ResumeShipmentAlert 
            :language="language"
            :locals="locals"
          />
        </s-button>
      </template>
      <!-- Delete Order,删除订单 -->
      <s-button
        v-if="
          ((orderInfo.isCanBeHiddenByUser && orderType != 'oldorder') ||
            (confirmOver && fromRecycle != 'recycleOrderList')) &&
            orderResultAbtInfo.abTestConfig['DeleteOrder'] !== 'on'
        "
        DA-view-type="ordinary"
        DA-type="syncClick"
        DA-sa-name="delete_order"
        :type="['H56PX']"
        class="order-detail-footer__btn"
        @click="delOrder()"
      >
        {{ language.SHEIN_KEY_PWA_16072 }}
      </s-button>
      <!-- confirmDeliver alter -->
      <div
        v-show="isShowBtnMore"
        class="my-btn-more-list"
      >
        <div
          ref="list-btn-more"
          class="btn-more-list"
          style="display: none"
        >
          <p
            v-for="(item, index) in btnMoreList"
            :key="index"
            v-expose="getBtnMoreAnalysis(item)"
            class="btn-more"
            :class="item.addClass"
            @click="handleBtnMoreItem(item)"
            v-html="item.text"
          ></p>
        </div>
        <a
          v-tap="{
            id: '1-19-1-147',
            data: { order_status: orderInfo.orderStatus }
          }"
          @click="handleBtnListShow('list-btn-more')"
        >
          <span 
            v-if="returnStyle.MoreStyle === 'text' && locals.lang != 'euqs'"
            class="more-text"
          >
            {{ language.SHEIN_KEY_PWA_23768 }}
          </span>
          <template v-else>
            <span class="point"></span>
            <span class="point"></span>
            <span class="point"></span>
          </template>
        </a>
      </div>
    </div>
    
    <ClientOnly>
      <!-- 确认收货触发发积分场景事前校验 -->
      <lazy-mount>
        <s-dialog
          :visible.sync="orderDeliveryPointCheckTips.showModal"
          :show-close="false"
        >
          <template slot="title">
            <div
              tabindex="0"
              v-html="htmlDecode(orderDeliveryPointCheckTips.text)"
            ></div>
          </template>
          <template slot="footer">
            <s-button-group
              :width="'100%'"
              hor
              :num="2"
            >
              <s-button-group-item
                :type="['primary', 'H80PX']"
                @click="confirmDeliver"
              >
                {{ language.SHEIN_KEY_PWA_15252 }}
              </s-button-group-item>
              <s-button-group-item
                :type="['H80PX']"
                @click="cancelPreCheckConfirmDeliver"
              >
                {{ language.SHEIN_KEY_PWA_15253 }}
              </s-button-group-item>
            </s-button-group>
          </template>
        </s-dialog>
      </lazy-mount>

      <!-- confirmDeliver alter tips -->
      <lazy-mount>
        <s-dialog
          :visible.sync="confirmDeliverMessage"
          :show-close="false"
          @close="closeConfirmDeliver"
        >
          <template slot="title">
            <div
              tabindex="0"
              v-html="confirmDeliverMessageText"
            ></div>
          </template>
          <template slot="footer">
            <s-button
              :width="'100%'"
              :type="['primary', 'H80PX']"
              @click="confirmDeliverMessageEvt"
            >
              {{
                orderDeliveryForm.isCanComment &&
                  (orderDeliveryForm.isCanComment === 1 || orderDeliveryForm.isCanComment === 3) &&
                  !orderDeliveryForm.isFreeTrial
                  ? `${language.SHEIN_KEY_PWA_17939}`
                  : `${language.SHEIN_KEY_PWA_15146}`
              }}
            </s-button>
          </template>
        </s-dialog>
      </lazy-mount>

      <!-- 试用订单确认收货成功且没有命中积分熔断校验，confirmDeliver alter tips -->
      <lazy-mount>
        <s-dialog
          :visible.sync="confirmDeliverUpload"
          :show-close="false"
          class="confirm-deliver-alter-modal confirm-deliver-upload-modal"
          @close="closeConfirmDeliverUpload"
        >
          <template slot="title">
            <div tabindex="0">
              {{ language.SHEIN_KEY_PWA_18258 }}
            </div>
          </template>
          <template slot="footer">
            <s-button-group :width="'100%'">
              <s-button-group-item
                :type="['primary', 'H80PX']"
                @click="toEditReport(orderDeliveryForm.uploadTryStatus, 'popup')"
              >
                {{ language.SHEIN_KEY_PWA_18266 }}
              </s-button-group-item>
              <s-button-group-item
                :type="['H80PX']"
                @click="closeConfirmDeliverUpload('button')"
              >
                {{ language.SHEIN_KEY_PWA_16092 }}
              </s-button-group-item>
            </s-button-group>
          </template>
        </s-dialog>
      </lazy-mount>

      <!-- codOrderIsNotSupportReceipt alter -->
      <lazy-mount>
        <s-dialog
          :visible.sync="codOrderIsNotSupportReceipt"
          :show-close="false"
          :close-on-click-modal="false"
          @close="codOrderIsNotSupportReceipt = false"
        >
          <template slot="title">
            <div
              tabindex="0"
              v-html="codOrderIsNotSupportReceiptTip"
            ></div>
          </template>
          <template slot="footer">
            <s-button
              :width="'100%'"
              :type="['primary', 'H80PX']"
              @click="codOrderIsNotSupportReceiptBiGA"
            >
              {{ language.SHEIN_KEY_PWA_15146 }}
            </s-button>
          </template>
        </s-dialog>
      </lazy-mount>

      <!-- 超过时效提示 -->
      <lazy-mount>
        <s-dialog
          :visible.sync="isShowOverTimeLimit"
          :show-close="false"
          append-to-body
        >
          <template slot="title">
            <div tabindex="0">
              {{ htmlDecode(language.SHEIN_KEY_PWA_16451) }}
            </div>
          </template>
          <template slot="footer">
            <s-button
              :width="'100%'"
              :type="['primary', 'H80PX']"
              @click="toggleShowOverTimeLimit"
            >
              {{ language.SHEIN_KEY_PWA_15146 }}
            </s-button>
          </template>
        </s-dialog>
      </lazy-mount>

      <!-- 疫情影响提示  -->
      <lazy-mount>
        <s-dialog
          :visible.sync="isShowReturnUnavailableModal"
          :show-close="false"
          :append-to-body="true"
          @close-from-icon="isShowReturnUnavailableModal = false"
        >
          <template slot="title">
            <div
              class="detail-footer-text-left"
              tabindex="0"
              v-html="isSheinAPP ? language.SHEIN_KEY_PWA_17097 : language.SHEIN_KEY_PWA_17113"
            ></div>
          </template>
          <template slot="footer">
            <s-button
              :width="'100%'"
              :type="['primary', 'H80PX']"
              @click="isShowReturnUnavailableModal = false"
            >
              {{ language.SHEIN_KEY_PWA_15146 }}
            </s-button>
          </template>
        </s-dialog>
      </lazy-mount>

      <!-- abtest关闭功能提示 -->
      <lazy-mount>
        <s-dialog
          :visible.sync="unavailableModal"
          :show-close="false"
          :append-to-body="true"
          @close-from-icon="toggleUnavailableModal"
        >
          <template slot="title">
            <div
              class="detail-footer-text-left"
              tabindex="0"
              v-html="htmlDecode(language.SHEIN_KEY_PWA_17115)"
            ></div>
          </template>
          <template slot="footer">
            <s-button
              :width="'100%'"
              :type="['primary', 'H80PX']"
              @click="toggleUnavailableModal"
            >
              {{ language.SHEIN_KEY_PWA_15146 }}
            </s-button>
          </template>
        </s-dialog>
      </lazy-mount>

      <lazy-mount>
        <s-dialog
          :visible.sync="isShowUrgeShipmentDialog"
          :show-close="true"
        >
          <template #title>
            {{ urgeshipmentText.title }}
          </template>
          <div>{{ urgeshipmentText.content }}</div>
          <template #footer>
            <s-button
              :type="['primary']"
              :width="'100%'"
              @click="isShowUrgeShipmentDialog = false"
            >
              {{ language.SHEIN_KEY_PWA_26382 }}
            </s-button>
          </template>
        </s-dialog>
      </lazy-mount>

      <lazy-mount>
        <s-dialog :visible.sync="isShowTimeTipsDialog">
          <p class="timeover-tips">
            {{ timeTips }}
          </p>
          <template slot="footer">
            <s-button
              width="100%"
              :type="['primary', 'H72PX']"
              @click="isShowTimeTipsDialog = false"
            >
              {{ language.SHEIN_KEY_PWA_15146 }}
            </s-button>
          </template>
        </s-dialog>
      </lazy-mount>

      <lazy-mount>
        <s-dialog :visible.sync="showFrontConditionDialog">
          <div>{{ showFrontConditionText }}</div>
          <template slot="footer">
            <s-button
              width="100%"
              :type="['primary', 'H72PX']"
              @click="showFrontConditionDialog = false"
            >
              {{ language.SHEIN_KEY_PWA_15146 }}
            </s-button>
          </template>
        </s-dialog>
      </lazy-mount>

      <lazy-mount>
        <s-dialog :visible.sync="showExchangeTipsPop">
          <div class="exchange-tips">{{ disableExchangeTips }}</div>
          <template slot="footer">
            <s-button
              width="100%"
              :type="['primary', 'H72PX']"
              @click="showExchangeTipsPop = false"
            >
              {{ language.SHEIN_KEY_PWA_26382 }}
            </s-button>
          </template>
        </s-dialog>
      </lazy-mount>

      <!-- 确认收货新提示 -->
      <LazyMount mount-prop-name="show">
        <confirmDeliveryTips
          :show="showConfirmDeliveryTips"
          :language="language"
          :goodsList="confirmDeliveryGoodsInfo"
          @cancelConfirmDeliver="cancelConfirmDeliver"
          @preCheckPointByConfirmOrder="preCheckPointByConfirmOrder"
        />
      </LazyMount>
      <LazyMount mount-prop-name="show">
        <repurchase-confirm-dialog
          :billno="orderInfo.billno"
          :language="language"
          :show="repurchaseModel"
          @handleSave="handleRepurchase"
        />
      </LazyMount>

      <!-- 催派业务 -->
      <UrgeDeliveryModal
        v-if="showUrgeDeliveryBtn"
        ref="urgeDeliveryModalRef"
        page-from="order_detail"
        :order-info="orderInfo"
        :language="language"
      />

      <!-- 留评风控提示 -->
      <LazyMount>
        <RiskCommentDialog
          :visible.sync="showRiskCommentTips"
          :language="language"
          @close-risk-comment-tips="closeRiskCommentTips"
        />
      </LazyMount>

      <UrgentPickUp
        ref="urgentPickUp"
        :language="language"
      />

      <!-- 签收提示 -->
      <lazy-mount>
        <s-dialog
          :visible.sync="isShowOldSign"
          :show-close="false"
        >
          <template slot="title">
            <div tabindex="0">
              {{ htmlDecode(language.SHEIN_KEY_PWA_16447) }}
            </div>
          </template>
          <template slot="footer">
            <s-button-group
              :width="'100%'"
              hor
              :num="2"
            >
              <s-button-group-item
                :type="['primary', 'H80PX']"
                @click="toggleReturnItemSign('yes')"
              >
                {{ language.SHEIN_KEY_PWA_15252 }}
              </s-button-group-item>
              <s-button-group-item
                :type="['H80PX']"
                @click="
                  type => {
                    toggleReturnItemSign(type === 'btn' ? 'no' : 'close')
                  }
                "
              >
                {{ language.SHEIN_KEY_PWA_15253 }}
              </s-button-group-item>
            </s-button-group>
          </template>
        </s-dialog>
      </lazy-mount>

      <!-- 退货二次确认 -->
      <LazyMount>
        <ReturnConfirmPop
          :visible="isShowSign"
          :language="language"
          :orderInfo="orderInfo"
          :trackInfo="latestTrackInfo"
          :from="'order_detail'"
          @close-confirm-pop="toggleReturnItemSign('close')"
          @confirm-return="toggleReturnItemSign('yes')"
        />
      </LazyMount>

      <!-- 退货时效过期 -->
      <LazyMount>
        <ReturnExpirePop
          :visible="showExpirePop"
          :language="language"
          :package-return-time="packageReturnTime"
          :order-return-time="orderReturnTime"
          :order-info="orderInfo"
          @close-expire-pop="showExpirePop = false"
        />
      </LazyMount>

      <!-- 下载COD订单支付凭证 -->
      <LazyMount>
        <PaymentReceiptDialog
          :visible.sync="showPaymentReceiptDialog"
          :language="language"
          :order-info="orderInfo"
        />
      </LazyMount>
    </ClientOnly>
  </div>
</template>

<script>
import Vue from 'vue'
import ClientOnly from 'vue-client-only'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { htmlDecode, getQueryString, getLocalStorage, setLocalStorage, template } from '@shein/common-function'
import returnItemMixin from '../common/returnItemMixin'
import orderLogic from 'public/src/pages/common/orderLogic'
import { Button, ButtonGroup, Dialog, ButtonGroupItem, LazyMount } from '@shein/sui-mobile'
import orderFactory from '../common/orderFactory.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import { abtservice } from 'public/src/services/abt'
import { filterAttrValue } from 'public/src/pages/common/utils/index.js'
import schttp from 'public/src/services/schttp'
import EditAddressBtnNew from 'public/src/pages/user/child_pages/orders/detail/components/editAddressBtnNew'
import PayNowButton from './components/PayNowButton'
import PayNowSwiper from './components/PayNowSwiper'
import RepurchaseLabel from './components/RepurchaseLabel.vue'
import ResumeShipmentAlert from './components/ResumeShipmentAlert.vue'
import applePayConfig from 'public/src/pages/common/apple_pay/index.js'
import { REPURCHASE_ORDER_STATUS } from 'public/src/pages/user/child_pages/orders/common/constants.js'
import { uniq } from 'lodash'

;[Button, Dialog, ButtonGroup, ButtonGroupItem].forEach(u => { Vue.use(u) })

daEventCenter.addSubscriber({ modulecode: '1-19-1' })
daEventCenter.addSubscriber({ modulecode: '1-33-1' })
let timer = null

export default {
  name: 'DetailFooter',
  directives: { tap, expose },
  components: {
    LazyMount,
    ClientOnly,
    EditAddressBtnNew,
    PayNowButton,
    PayNowSwiper,
    RepurchaseLabel,
    ResumeShipmentAlert,
    PaymentReceiptDialog: () => import(/* webpackChunkName: "OrderDetailFooter_Comp" */'./components/PaymentReceiptDialog.vue'),
    repurchaseConfirmDialog: () => import(/* webpackChunkName: "OrderDetailFooter_Comp" */'../common/repurchaseConfirmDialog.vue'),
    confirmDeliveryTips: () => import(/* webpackChunkName: "OrderDetailFooter_Comp" */'public/src/pages/user/child_pages/orders/list/confirmDeliveryTips.vue'),
    UrgeDeliveryModal: () => import(/* webpackChunkName: "OrderDetailFooter_Comp" */'public/src/pages/user/child_pages/orders/common/urge-delivery/UrgeDeliveryModal.vue'),
    RiskCommentDialog: () => import(/* webpackChunkName: "OrderDetailFooter_Comp" */ 'public/src/pages/user/child_pages/orders/common/RiskCommentDialog.vue'),
    ApplePayBtn: () => import(/* webpackChunkName: "OrderDetail_ApplePayBtn" */'public/src/pages/components/ApplePayBtn.vue'),
    UrgentPickUp: () => import(/* webpackChunkName: "OrderDetailFooter_Comp" */ 'public/src/pages/user/child_pages/order_return/records/components/urgentPickUp.vue'),
    ReturnConfirmPop: () => import(/* webpackChunkName: "OrderDetailFooter_Comp" */'public/src/pages/user/child_pages/orders/common/returnTime/ReturnConfirmPop.vue'),
    ReturnExpirePop: () => import(/* webpackChunkName: "OrderDetailFooter_Comp" */'public/src/pages/user/child_pages/orders/common/returnTime/ReturnExpirePop.vue')
  },
  mixins: [returnItemMixin],
  props: {
    newOrderInfo: {
      type: Object,
      default: () => {}
    },
    countDown: {
      type: Object,
      default: () => {}
    },
    paypalBtnShow: Boolean,
    paypalBnplBtnShow: Boolean,
    detailNewFnAbt: {
      type: String,
      default: ''
    },
    paypalVenmoBtnShow: Boolean,
  },
  data () {
    return {
      isShowUrgeShipmentForInferface: false,
      isShowUrgeShipmentDialog: false,
      isShowUrgeShipmentedDialog: false,
      timeTips: '',
      abtFlag: false,
      isShowTimeTipsDialog: false,
      confirmDeliverAlter: false,
      confirmDeliverAlterTips: '',
      confirmDeliverMessage: false,
      confirmDeliverUpload: false,
      confirmDeliverMessageText: '',
      confirmDeliverMessagePoints: 0,
      isDoublePointsOrder: false,
      adjuctFlag: true,
      codOrderIsNotSupportReceipt: false, // cod订单是否不支持确认收货
      codOrderIsNotSupportReceiptTip: '',
      showConfirmDeliveryTips: false,
      orderDeliveryForm: {
        points: 0,
        isDoublePoints: false,
        confirmDeliveryPoints: 0,
        doublePoints: 0,
        billno: '',
        isCanComment: 0,
        siteFrom: '',
        isFreeTrial: false,
        uploadTryStatus: {}
      },
      orderDeliveryPointCheckTips: {
        showModal: false,
        text: '',
        points: 0
      },
      adustTimerId: null,
      isPointCheckModle: false,
      btnMoreList: [],
      isShowBtnMore: false,
      cashPayMethod: ['adyen-konbini', 'adyen-multibanco'],
      originPayMethod: '', //初始值要为空，要记录初始支付
      repurchaseModel: {
        show: false
      },
      showFrontConditionDialog: false,
      showFrontConditionText: '',
      showUrgeDeliveryModal: false,
      showExchangeTipsPop: false,
      showRiskCommentTips: false,
      isRiskComment: false, //是否风控评论,
      urgeshipmentText: {
        content: '',
        title: ''
      },
      showPaymentReceiptDialog: false,
      billno: '',
      defaultMoreOpen: false
    }
  },
  computed: {
    ...mapState('orderDetail', [
      'language',
      'orderType',
      'showPartOperatorBtn',
      'pageStatus',
      'payments',
      'inReturnItem',
      'cancelItemListFlag',
      'originPaymentMethod',
      'exchangeInfo',
      'orderResultAbtInfo',
      'editAddressNewConfigList',
      'isFreeRefund',
      'apolloInfo',
      'locals',
      'orderInfo',
      'pageLoading',
      'fromRecycle',
      'orderStatusTextList',
      'urgePickupInfoList',
      'paymentReceiptList',
    ]),
    ...mapState('root', ['rootStatus']),
    ...mapGetters('orderDetail', ['ordersStatus', 'getCodOrderFrom']),
    isUnPaidOrder () {
      return [0, 12, 13]?.includes(+this.orderInfo?.orderStatus)
    },
    // 展示标记按钮
    markOrderShow() {
      const markOrderParams =  this.orderResultAbtInfo.markOrderParams || {}
      const reasonList = ['1', '3', '297']
      const orderMarkList = this.orderInfo.order_mark_list || []
      return (
        markOrderParams.mark_show == 'on' && 
        orderMarkList.some(i => i.mark_type == 1 && i.mark_type_status == 1 && reasonList.includes(i.trouble_reason))
      )
    },
    orderRepurchaseParam() {
      return this.orderResultAbtInfo.orderRepurchaseParam || {}
    },
    // 显订单详情回购显示利诱
    showOrderRepurchase() {
      const { PromosPosition = '' } = this.orderRepurchaseParam
      return Boolean(PromosPosition?.includes('detail_order'))
    },
    // 订单详情回购成功显示新的成功页
    showNewRepurchaseSuccess() {
      const { NewRepurchase } = this.orderRepurchaseParam
      return NewRepurchase === 'on'
    },
    noShowEditOrderAddressBtn() {
      if(this.showCancelOrderLink){
        return true
      }
      if(this.showEditOrderAddress){
        const editAddressNewConfigListItem = this.editAddressNewConfigList?.find(item=> item?.editable_order_list?.length)
        let hasMoreAddress = false
        let isCanEditable = false
        if(editAddressNewConfigListItem) {
          const values = editAddressNewConfigListItem?.editable_order_list.map(item => item?.transport_type || '')
          const uniqueValues = uniq(values)
          hasMoreAddress = uniqueValues.length !== 1 
          isCanEditable =  editAddressNewConfigListItem?.editable_order_list.find(item => item?.is_order_address_editable == 1 ) || false
          if (editAddressNewConfigListItem?.editable_order_list.find(item => item?.is_order_address_editable == 2 && item?.gray_code == 6 )) {
            isCanEditable = false
          }
        }
        return hasMoreAddress && isCanEditable && this.isUnPaidOrder
      } else {
        return true
      }
    },
    orderOperationSortedButtons () {
      const { orderStatus, payment_method } = this.orderInfo
      const isCod = payment_method === 'cod'
      const buttons = this.opeButtonSort(orderStatus)
      if (!buttons?.length) {
        return []
      }
      const btns = new Set(buttons)
      // upload report
      if (!this.isShowUploadReport) {
        btns.delete('upload_report')
      }
      // confirm_delivery
      if (!this.confirmDeliveryBtnShow) {
        btns.delete('confirm_delivery')
      }
      // view_invoices
      if (!this.isShowViewInvoices) {
        btns.delete('view_invoices')
      }
      // Review
      if (!this.reviewBtnShow) {
        btns.delete('review')
      }
      // MyReview
      if (!this.myReviewBtnShow) {
        btns.delete('my_review')
      }
      // pay_now,不可支付,可支付去掉类disabld 
      const isShowPayNow = (orderStatus == 0 || orderStatus == 12) && !this.paypalBtnShow && !this.paypalBnplBtnShow
      if (!isShowPayNow) {
        btns.delete('pay_now')
      }
      // verify,in站不能在ar站短信验证
      if (orderStatus != 13) {
        btns.delete('verify')
      }
      // track
      const isShowTrack = this.trackItemShow(this.orderInfo) && this.showPartOperatorBtn
      if (!isShowTrack) {
        btns.delete('track')
      }
      // Return Item
      if (!this.isReturnableInfo(this.orderInfo)) {
        btns.delete('return_item')
      }
      // cancel order
      if (!this.orderCancel(this.orderInfo)) {
        btns.delete('cancel_order')
      }
      // cancel item
      const isShowCancelItem = this.isCommonShowCancelButton(this.orderInfo) && this.showPartOperatorBtn
      if (!isShowCancelItem) {
        btns.delete('cancel_item')
      }
      // 已短信验证未发货的单件撤销  Revoke item
      if (!this.isCodOrderCanPartRefund(this.orderInfo) || !this.orderResultAbtInfo?.isRevokeItemOn) {
        btns.delete('revoke_item')
      }
      // Repurchase,回购
      const isShowRepurchase = REPURCHASE_ORDER_STATUS.includes(+orderStatus) &&
        this.orderType != 'oldorder' &&
        this.orderResultAbtInfo.abTestConfig['Repurchase'] !== 'on' && 
        !orderLogic.isRelationBillno(this.orderInfo.billno) && //不是U单
        ![1, 7].includes(+this.orderInfo.marketing_type) && // 不是生日礼/试用订单
        this.showPartOperatorBtn && 
        this.isShowRepurchaseBtn(this.orderInfo)
      if (!isShowRepurchase) {
        btns.delete('repurchase')
      }
      // one click pay
      if (!this.orderInfo.showOneClickPay) {
        btns.delete('one_click_pay')
      }
      // urge_shipment
      const isShowUrgeShipment = this.orderInfo?.urgeShipmentInfo?.isShowUrgeShipment && [1, 4, 6].includes(+orderStatus)
      if (!isShowUrgeShipment) {
        btns.delete('urge_shipment')
      }
      // 催派
      if (!this.showUrgeDeliveryBtn) {
        btns.delete('urge_delivery')
      }
      // Exchange
      if (!this.showExchangeBtn) {
        btns.delete('exchange')
      }
      // 新编辑按钮
      if (this.noShowEditOrderAddressBtn) {
        btns.delete('edit_address')
      }
      // 催取件 urgent_pickup
      if (!this.urgentPickupList?.length){
        btns.delete('urgent_pickup')
      }
      // 下载COD订单支付凭证
      if (
        !this.orderResultAbtInfo?.showCodPaymentReceipt ||
        !isCod || 
        ![5, 7, 10, 11, 15, 17].includes(+orderStatus) ||
        !this.paymentReceiptList.length
      ) {
        btns.delete('payment_receipt')
      }
      // 自主标记
      if (!this.markOrderShow) {
        btns.delete('resume_shipment')
      }
      return [...btns]
    },
    isSheinAPP () {
      return this.locals.WEB_CLIENT != 'romwe'
    },
    // 二次支付氛围提升探索abt
    payNowAbtInfo() {
      return this.orderResultAbtInfo?.payNowAbtInfo || {}
    },
    returnStyle() {
      return this.orderResultAbtInfo?.returnStyleParam || {}
    },
    // 控制订单详情底部利诱样式的展示
    orderDetailPromotionTest() {
      return this.payNowAbtInfo.OrderdetailPromotionTest || ''
    },
    // 订单详情开启利诱点信息实验
    showOrderDetailPromotionTest() {
      return ['on1', 'on2'].includes(this.orderDetailPromotionTest)
    },
    payNowText() {
      if (this.payNowAbtInfo.OrderdetailButtonTest === 'on' && this.orderInfo.totalPrice?.amountWithSymbol) {
        return template(this.orderInfo.totalPrice.amountWithSymbol, this.language.SHEIN_KEY_PWA_30861)
      }
      return this.language.SHEIN_KEY_PWA_15655
    },
    showRefundNew() {
      return Boolean(this.orderResultAbtInfo?.showRefundNew)
    },
    analysisData() {
      const labelMap = {
        1: 'save',
        2: 'freeShipping',
        3: 'points',
        4: 'freeReturn',
        5: 'priceGuarantee',
        6: 'creditReward'
      }
      const labelList = this.payNowLabelList.map(item => {
        return labelMap[item.inducementType] || ''
      })
      return {
        order_no: this.orderInfo.billno,
        inducement_type: labelList.join(',') 
      }
    },
    isRepurchaseLabelInMore() {
      const isInclude = this.btnMoreList.some(item => item?.text.includes(this.language.SHEIN_KEY_PWA_16211))
      return Boolean(isInclude)
    },
    repurchaseAnalysisData() {
      const { orderGoodsList = [] } = this.orderInfo
      const goodsIds = orderGoodsList.flat(Infinity).map(item => item.goods_id)
      return {
        goods_id: uniq(goodsIds).join(','),
        is_promoshow: !this.isRepurchaseLabelInMore && this.orderInfo.repurchase_lure_info?.tip ? 1 : 0,
        position: 'order'
      }
    },
    showCancelOrderLink() {
      return this.showOrderDetailPromotionTest && [0, 12].includes(+this.orderInfo.orderStatus)
    },
    detailFooterShow () {
      const order = this.orderInfo
      if (
        this.orderCancel(order) ||
        order.isCanConfirmDelivery ||
        this.isCanRevokeByUser(order) ||
        this.isReturnableInfo(order) ||
        order.isCanBeHiddenByUser ||
        this.orderType != 'oldorder' ||
        this.isCommonShowCancelButton(order) ||
        this.fromRecycle
      ) {
        return true
      } else {
        return false
      }
    },
    showDetailFooter() {
      return this.detailFooterShow && !this.pageLoading
    },
    confirmDeliveryBtnShow() {
      return (
        this.orderInfo?.isCanConfirmDelivery &&
        this.orderType != 'oldorder' &&
        this.orderResultAbtInfo?.isShowConfirmDelivery
      )
    },
    myReviewBtnShow() {
      return (
        (this.orderInfo?.isCanComment == 2 || this.orderInfo?.isCanComment == 3) &&
        this.orderResultAbtInfo?.isShowMyReview &&
        this.orderInfo?.marketing_type != 1
      )
    },
    reviewBtnShow() {
      return orderLogic.isOrderReview({ order: this.orderInfo }) && this.orderResultAbtInfo?.isShowReview
    },
    isShowUploadReport() {
      if (!(this.orderInfo?.uploadTryStatus?.status && this.orderResultAbtInfo?.uploadReportShow && !this.IS_RW)) {
        return false
      }
      return [2, 3].includes(+this.orderInfo.uploadTryStatus.status)
    },
    urgentPickupList() {
      const { billno } = this.orderInfo
      const urgeInfo = this.urgePickupInfoList.find(item => item?.bill_no == billno)

      return urgeInfo?.return_order_list?.filter(item => item?.urge_pick_up) || []
    },
    // 是否显示查看发票按钮
    isShowViewInvoices() {
      const isViewInvoices = orderLogic.isViewInvoices(this.orderInfo, 'order-detail')
      return this.orderResultAbtInfo?.isOpenViewInvoicesByAbt && isViewInvoices
    },
    // 是否展示催派按钮
    showUrgeDeliveryBtn() {
      const { urgeDeliveryInfo, billno } = this.orderInfo

      return urgeDeliveryInfo?.find(item => item?.billno == billno)?.is_display_delivery == 1
    },
    // 判断是否满足面单前置条件
    overFrontCondition() {
      if (!this.orderResultAbtInfo?.isInfoFrontAbt || !orderLogic.isWillShipOrder(this.orderInfo)) return 0
      return orderLogic.existFrontFlag(this.orderInfo)
    },
    overCancelItemCondition() {
      return orderLogic.orderDetailCancelItemCond(this.orderInfo, this.orderResultAbtInfo?.isCanRefundAbt)
    },
    orderBusiModel() {
      let { _allOrderGoodsList = [] } = this.orderInfo || {}
      let tempGoodsList = _allOrderGoodsList?.flat(Infinity)
      let busiModelList = tempGoodsList?.map(list => +list.business_model)
      if (busiModelList.includes(1) && busiModelList.includes(0)) return 3
      if (busiModelList.includes(1)) return 2
      return 1
    },
    confirmDeliveryGoodsInfo() {
      return this.orderInfo?._allOrderGoodsList || []
    },
    // 超过确认收货时长
    confirmOver() {
      const { delivery_time = 0, confirm_receipt_time = 0 } = this.orderInfo
      if (delivery_time && confirm_receipt_time) {
        // 获取当前秒时间戳
        const nowTime = Math.floor(new Date().getTime() / 1000)
        // 发货时长
        const confirmOverTime = (nowTime - delivery_time) / 86400
        // 如果发货时长大于确认收货时长，漏出删除按钮
        return confirmOverTime > confirm_receipt_time
      }

      return false
    },
    disabledCancelItem() {
      return orderLogic.disabledCancelButton({
        type: 'cancel_item',
        order: this.orderInfo,
        showRefundNew: this.showRefundNew,
      })
    },
    isEnableCancelAbt() {
      return orderLogic.isEnableCancelAbt({
        type: 'cancel_item',
        abTestConfig: this.orderResultAbtInfo.abTestConfig,
        showRefundNew: this.showRefundNew,
      })
    },
    disabledCodOrderPartRefund() {
      return orderLogic.disabledCodOrderPartRefund(this.orderInfo)
    },
    showExchangeBtn() {
      return [1, 2].includes(this.exchangeInfo?.can_exchange)
    },
    exchangeDisabled() {
      return this.exchangeInfo?.can_exchange == 2
    },
    disableExchangeTips() {
      /**
       * 0: 商品状态不满足（兜底态）
       * 3: 换货次数校验不符
       */
      const ReasonType = {
        DEFAULT: 0,
        MAX_LIMIT: 3,
      }
      const ResonLanguageMap = {
        [ReasonType.DEFAULT]: 'SHEIN_KEY_PWA_29071',
        [ReasonType.MAX_LIMIT]: 'SHEIN_KEY_PWA_29070',
      }
      const langKey = ResonLanguageMap[this.exchangeInfo?.code] || ResonLanguageMap[ReasonType.DEFAULT]

      return this.language[langKey]
    },
    batchModifyAddressAbt () {
      return this.orderResultAbtInfo?.batchModifyAddressAbt
    },
    // 保价险abt开关
    showPriceGuaranteeAbt() {
      return !!this.orderResultAbtInfo?.showPriceGuaranteeAbt
    },
    showEditOrderAddress () {
      return this.orderResultAbtInfo?.showEditOrderAddress
    },
    newEditAddressOptions () {
      return {
        batchModifyAddress: this.batchModifyAddressAbt,
        pageSource: 'OrderDetail',
        btnSource: 'Edit'
      }
    },
    // 利诱类型abt实验列表
    testTypeList() {
      const abtTypeInfo = this.payNowAbtInfo.OrderdetailInducementType
      if (typeof abtTypeInfo === 'string' && abtTypeInfo) {
        return abtTypeInfo.split(',')
      }
      return []
    },
    // 是否显示免邮信息
    showShippingFree() {
      if (this.testTypeList.includes('2') && this.orderInfo.shippingPrice) {
        const amount = this.orderInfo.shippingPrice.amount
        return parseFloat(amount) === 0
      }
      return false
    },
    // 显示价保条件
    showPriceGuarantee() {
      return (
        this.testTypeList.includes('5') &&
        this.showPriceGuaranteeAbt &&
        this.orderInfo.oldOrderGoodsList?.some(item => item.price_tag == 1)
      )
    },
    // 显示付费会员钱包余额权益
    showPaidMemberBenefits() {
      return this.orderInfo?.credit_amount?.amount > 0
    },
    // 利诱列表
    payNowLabelList() {
      const labelList = []
      // 非未支付、未配置诱样式的、未配置利诱类型的不展示利诱信息
      if (!this.showOrderDetailPromotionTest || ![0, 12].includes(+this.orderInfo.orderStatus) || !this.testTypeList.length) {
        return labelList
      }
      const testTwo = this.orderDetailPromotionTest == 'on2'
      // 1-订单优惠信息
      if (this.testTypeList.includes('1') && this.orderInfo.total_discount_new?.amount > 0) {
        labelList.push({
          type: 'discount',
          name: 'sui_icon_coupon_discount_14px',
          inducementType: '1',
          text: template(
            this.orderInfo.total_discount_new.amountWithSymbol,
            this.language.SHEIN_KEY_PWA_30862
          )
        })
      }
      // 2-免运费信息
      if (this.showShippingFree) {
        labelList.push({
          type: 'success',
          name: 'sui_icon_free_shipping_14px',
          inducementType: '2',
          text: testTwo ? this.language.SHEIN_KEY_PWA_30865 : this.language.SHEIN_KEY_PWA_30859
        })
      }
      // 3-积分信息
      if (this.testTypeList.includes('3') && this.orderInfo.total_point) {
        labelList.push({
          type: 'success',
          name: 'sui_icon_points_14px',
          inducementType: '3',
          text: template(this.orderInfo.total_point, this.language.SHEIN_KEY_PWA_30857)
        })
      }
      // 4-免费退信息
      if (this.testTypeList.includes('4') && this.isFreeRefund) {
        labelList.push({
          type: 'success',
          name: 'sui_icon_free_return_14px',
          inducementType: '4',
          text: testTwo ? this.language.SHEIN_KEY_PWA_30863 : this.language.SHEIN_KEY_PWA_30860
        })
      }
      // 5-价保信息
      if (this.showPriceGuarantee) {
        labelList.push({
          type: 'success',
          name: 'sui_icon_guarantee_price_green_14px',
          inducementType: '5',
          text: testTwo ? this.language.SHEIN_KEY_PWA_30864 : this.language.SHEIN_KEY_PWA_30870
        })
      }
      // 6-付费会员钱包余额权益
      if (this.testTypeList.includes('6') && this.showPaidMemberBenefits) {
        labelList.push({
          type: 'credit',
          name: 'sui_icon_club_logo_fill_14px',
          inducementType: '6',
          text: template(this.orderInfo?.credit_amount?.amountWithSymbol, this.language.SHEIN_KEY_PWA_33143)
        })
      }
      labelList.sort((a, b) => {
        return this.testTypeList.indexOf(a.inducementType) - this.testTypeList.indexOf(b.inducementType) 
      })
      return labelList
    },
    applepayDropoffOptimizationAbt () {
      return this.orderResultAbtInfo?.showApplepayDropoffOptimization
    },
    applepayDropoffOptimizationApollo () {
      const applePayMethods = this.apolloInfo?.APPLE_PAY_CODE_LIST?.length ? this.apolloInfo.APPLE_PAY_CODE_LIST : applePayConfig?.applePayChannel || []
      return applePayMethods?.includes?.(this.orderInfo?.payment_method)
    },
    isShowApplepay() {
      // 未支付状态
      const isUnpaid = [0, 12, 13].includes(this.orderInfo?.orderStatus)

      return this.applepayDropoffOptimizationAbt
        && this.applepayDropoffOptimizationApollo
        && isUnpaid
    },
    latestTrackInfo() {
      const { orderGoodsList = [], trackList = [], order_package_info_list = [] } = this.orderInfo
      const curPackageNo = orderGoodsList.flat(Infinity)?.[0]?.reference_number || ''
      // 三仓包裹号
      const usCombinedPackage = order_package_info_list
        ?.filter(item => [1, 2, 3].includes(item?.confluence_pkg_status))
        ?.map(item => item?.packageNo) || []
      const isSign = order_package_info_list?.find(item => item?.packageNo == curPackageNo)?.signed_time
      const existUnsignSpecialGoods = orderGoodsList.flat(Infinity).some(item => [23, 49, 16, 52, 76, 90, 91, 57, 94, 54, 155, 154, 156].includes(+item.status))

      if (isSign || !existUnsignSpecialGoods) {
        return null
      }

      if (usCombinedPackage.length == order_package_info_list?.length) {
        // 如果全部是美国三仓合流
        return trackList.find(item => item?.package_no == curPackageNo)
      } else {
        return trackList.find(item => !usCombinedPackage.includes(item?.package_no))
      }
    },
    currentPackageNo() {
      const { orderGoodsList = [] } = this.orderInfo

      return orderGoodsList.flat(Infinity)?.[0]?.reference_number || ''
    },
    returnTime() {
      return orderLogic.getReturnTimeInfo({
        language: this.language,
        orderInfo: this.orderInfo,
        localData: this.locals
      })
    },
    orderReturnTime() {
      return this.returnTime?.orderReturnTime || {}
    },
    packageReturnTime() {
      return this.returnTime.packageReturnTime?.find(item => item.packageNo == this.currentPackageNo)
    },
    isHideReturnItemBtn () {
      return this.btnMoreList?.find(item => item?.el?.[0]?.name == 'returnItem')
    }
  },
  watch: {
    orderInfo: {
      handler(val) {
        this.initDetailFooterEvt(val)
        this.setReturnMixinAbt(this.orderResultAbtInfo)
      },
      deep: true
    },
    showDetailFooter(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.adjuctOrderListMore()
        }, 1000)
      }
    },
    confirmDeliveryBtnShow: {
      handler(val) {
        if (val) {
          daEventCenter.triggerNotice({
            daId: '1-19-1-13'
          })
        }
      },
      immediate: true
    },
    myReviewBtnShow: {
      handler(val) {
        if (val) {
          daEventCenter.triggerNotice({
            daId: '1-19-1-18'
          })
        }
      },
      immediate: true
    },
    reviewBtnShow: {
      handler(val) {
        if (val) {
          daEventCenter.triggerNotice({
            daId: '1-19-1-20'
          })
        }
      },
      immediate: true
    },
    isShowUploadReport: {
      handler(val) {
        if (val) {
          daEventCenter.triggerNotice({
            daId: '1-19-1-37',
            extraData: {
              status: this.orderInfo.uploadTryStatus.status == 2 ? '1' : '2'
            }
          })
        }
      },
      immediate: true
    },
    // 显示查看发票按钮时触发曝光埋点
    isShowViewInvoices: {
      handler(nv) {
        if (nv) {
          daEventCenter.triggerNotice({
            daId: '1-19-1-50',
            extraData: {
              page_name: SaPageInfo.page_name
            }
          })
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.handleMethod()
    const IS_SSR = typeof window == 'undefined'
    !IS_SSR && window?.appEventCenter?.$on('capitecBankSaved', () => {
      this.payNowCommonEvt({ payType: 2, confirmPayType: 'PayNow' })
    })
    this.initDetailFooterEvt(this.orderInfo)

    // 触发 updated
    this.billno = this.orderInfo?.billno || ''
  },
  updated() {
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
    timer = setTimeout(() => {
      this.$nextTick(() => {
        this.adjuctOrderListMore()
      })
    }, 0) 
  },
  methods: {
    ...mapMutations('orderDetail', ['assignState', 'handleEditPayment', 'showMsg']),
    ...mapMutations('root', ['changeRootStatus', 'showMaskTips', 'assignRootState']),
    htmlDecode: text => htmlDecode({ text }),
    filterAttrValue,
    orderCancel: orderLogic.orderCancel,
    isReturnableInfo: orderLogic.isReturnableInfo,
    isCanRevokeByUser: orderLogic.isCanRevokeByUser,
    isCodOrderCanPartRefund: orderLogic.isCodOrderCanPartRefund,
    isShowRepurchaseBtn: orderLogic.isShowRepurchaseBtn,
    resumeShipment() {
      this.$emit('resume-shipment')
    },
    initOpenMoreBtnEvt (orderInfo) {
      let { orderStatus = '' } = orderInfo || {}
      let isShowMoreBtn = ![0, 1, 4, 6, 12, 13].includes(+orderStatus)
      if (isShowMoreBtn && this.returnStyle.MoreShowStyle === 'show') {
        this.defaultMoreOpen = true
        this.$nextTick(() => {
          this.$refs['list-btn-more'].style.display = 'block'
        })
      }
    },
    getQuickShipTp() {
      const tabGoodsList = this.orderInfo.orderGoodsList
      let qsMap = {
        1: 2,
        2: 1,
        0: 0
      }
      let res = orderLogic.getQuickShipTransportTimeType(tabGoodsList)
      return qsMap[res]
    },
    initDetailFooterEvt (orderInfo) {
      if (orderInfo?.confirm_receipt_time && !this.abtFlag) {
        this.getTimeCanDelivryAbtEvt()
      }
      //记录原本支付方式，记录过后不再变动
      if (!this.originPayMethod) {
        this.originPayMethod = orderInfo.payment_method
      }

      this.initOpenMoreBtnEvt(orderInfo)
    },
    // 已支付非cod取消按钮是否显示
    isCommonShowCancelButton(order) {
      return orderLogic.isCommonShowCancelButton({
        type: 'cancel_item',
        order: order,
        showRefundNew: this.showRefundNew,
      })
    },
    toRecommendEvt () {
      window?.appEventCenter?.$emit?.('one-click-recommend-scroll', (type) => {
        daEventCenter.triggerNotice({
          daId: '1-19-1-91',
          bindData: {
            order_no: this.orderInfo.merge_buy_billno,
            type
          }
        })
      })
    },
    async urgeShipment() {
      if (!this.orderInfo.urgeShipmentInfo?.isShowUrgeShipment){
        return
      }
      const { billno = '' } = this.orderInfo
      daEventCenter.triggerNotice({
        daId: '1-19-1-121',
        extraData: {
          order_no: billno
        }
      })

      try {
        const cccText = orderLogic.getOrderStatusTextByOrderList(this.orderStatusTextList, {
          ...this.orderInfo,
          order_or_package_status: this.orderInfo.order_detail_status
        })

        if (this.orderInfo?.urgeShipmentInfo?.isExistUrgeShipWork) {
          this.urgeshipmentText.title = this.language.SHEIN_KEY_PWA_26380
          this.urgeshipmentText.content = cccText?.orderNotFirstDeliveryText || this.language.SHEIN_KEY_PWA_26381
        } else {
          this.changeRootStatus({
            loading: true
          })

          const res = await schttp({
            method: 'POST',
            url: '/api/orders/base/submitUrgeShipInfo',
            data: {
              billno
            }
          })

          if (res?.code == 0) {
            this.orderInfo.urgeShipmentInfo.isExistUrgeShipWork = true
          } else {
            this.changeRootStatus({
              loading: false
            })
            this.$toast(this.language.SHEIN_KEY_PWA_26383)
            return
          }

          this.urgeshipmentText.title = this.language.SHEIN_KEY_PWA_26378
          this.urgeshipmentText.content = cccText?.orderFirstDeliveryText || this.language.SHEIN_KEY_PWA_26379
        }
        this.changeRootStatus({
          loading: false
        })
        this.isShowUrgeShipmentDialog = true
      } catch (e) {
        console.error(e)
      }
    },
    /**
     * 确认收货逻辑
     */
    toDeliveryMask() {
      if (getQueryString({ key: 'type' }) == 'confirmDelivery') {
        this.confirmDeliverAlterFun()
        history.replaceState({}, '', location.href.split('?')[0])
      }
    },
    async getTimeCanDelivryAbtEvt() {
      this.abtFlag = true
      // eslint-disable-next-line @shein-aidc/abt/abt
      const abtInfo = await abtservice.getUserAbtResult({ posKeys: 'TimeCanDelivery' })
      const param = (abtInfo['TimeCanDelivery'] && abtInfo['TimeCanDelivery'].param) || ''
      if (param == 'on') {
        this.getConfirmOverTime()
      }
    },
    getConfirmOverTime() {
      const { delivery_time = 0, confirm_receipt_time = 0 } = this.orderInfo
      if (
        !delivery_time ||
        delivery_time == '0' ||
        !confirm_receipt_time ||
        confirm_receipt_time == '0'
      ) {
        this.timeTips = ''
      } else {
        // 获取当前秒时间戳
        const secondsSteps = parseInt(Date.parse(new Date()).toString().substr(0, 10))
        let confirmOverTime = (secondsSteps - delivery_time) / 86400 // 发货时长
        if (confirmOverTime > confirm_receipt_time) {
          // 如果发货时长大于确认收货时长, 判定为超时
          this.timeTips = this.getTimeTips(confirm_receipt_time)
        } else {
          this.timeTips = ''
        }
      }
    },
    // 获取超时文案
    getTimeTips(days) {
      const months = Math.floor(days / 30)
      if (months <= 12) {
        return this.language.SHEIN_KEY_PWA_19679.replace('{0}', months <= 1 ? 1 : months).replace(
          '{1}',
          months <= 1 ? this.language.SHEIN_KEY_PWA_19705 : this.language.SHEIN_KEY_PWA_19723
        )
      } else {
        const years = Math.floor(months / 12)
        return this.language.SHEIN_KEY_PWA_19679.replace('{0}', years <= 1 ? 1 : years).replace(
          '{1}',
          years <= 1 ? this.language.SHEIN_KEY_PWA_19700 : this.language.SHEIN_KEY_PWA_19701
        )
      }
    },
    /** End */
    revokeItemEvt() {
      if (this.disabledCodOrderPartRefund) {
        this.showFrontConditionText = this.language.SHEIN_KEY_PWA_20121
        this.showFrontConditionDialog = true
        return
      }
      if (this.overFrontCondition > 1) {
        daEventCenter.triggerNotice({
          daId: '1-19-1-72',
          extraData: {
            operation_from: 1
          }
        })
        this.showFrontConditionText = this.htmlDecode(this.language.SHEIN_KEY_PWA_20121)
        this.showFrontConditionDialog = true
        return
      }
      if (this.overCancelItemCondition) {
        this.showFrontConditionText = this.getB2B2CCondTips()
        this.showFrontConditionDialog = true
        return
      }
      daEventCenter.triggerNotice({
        daId: '1-19-1-38',
        extraData: {
          billno: this.orderInfo.billno
        }
      })
      if (this.orderResultAbtInfo?.isRevokeItemOff) {
        this.toggleUnavailableModal()
        return
      }
      this.$emit('cancelItemShow')
    },
    handleMethod () {
      this.handleReduction = orderFactory.createOrderReduction(this, () => {
        this.assignRootState({ showRestoredInfo: true })
        this.$parent.goBack()
      })
    },
    handleSave() {
      daEventCenter.triggerNotice({
        daId: '1-19-1-152',
        extraData: this.repurchaseAnalysisData
      })
      if (this.orderResultAbtInfo.abTestConfig['Repurchase'] === 'on') {
        this.toggleUnavailableModal()
        return
      }
      if (this.showNewRepurchaseSuccess) {
        this.handleRepurchase()
      } else {
        this.repurchaseModel.show = true
      }
    },
    handleRepurchase: async function () {
      let successTips = true
      const rpepurchasePageParam = this.orderResultAbtInfo.repurchasePageParam || {}
      if (rpepurchasePageParam.param != 'none') {
        successTips = false
      }

      if (this.showNewRepurchaseSuccess) {
        successTips = false
      } 
    
      this.$parent.handleSave(
        this.orderInfo.billno,
        async res => {
          if (this.showNewRepurchaseSuccess && res?.code == '0') {
            this.assignState({
              addCartStatus: {
                showDrawer: true,
                cartInfo: {
                  successInfoList: res.info?.success_info_list || [],
                  falidInfoList: res.info?.falid_info_list || [],
                  repurchaseInfo: this.orderInfo.repurchase_lure_info
                },
              }
            })
          } else {
            res?.code == '0' &&
            !successTips &&
            this.$emit('handleShowSuccessPanel', { from: 'orderDetail' })
          }
        },
        '',
        { successTips }
      )
    },
    // 封装公共的支付方法 start
    payNowCommonEvt(options = {}, source) {
      let { payType, confirmPayType } = options || {}
      if (payType == 1) {
        this.jumpPayNowEvt(source)
      } else {
        this.confirmPayNowEvt({ confirmPayType }, source)
      }
    },
    jumpPayNowEvt(source) {
      let { payment_method, payment_type, expireCountdown, pay_code_url, pay_url } = this.orderInfo || {}
      let { paymentMethod } = this.originPaymentMethod || {}

      if (paymentMethod != payment_method) {
        this.confirmPayNowEvt({ confirmPayType: 'ViewCode' })
        return
      }

      daEventCenter.triggerNotice({
        daId: '1-19-1-69',
        extraData: {
          bussiness_tp: this.orderBusiModel,
          buy_tp: orderLogic.getBuyTpInfo(this.orderInfo),
          order_no: this.orderInfo.billno,
          paybutton_location: source || 'below',
          inducement_type: this.analysisData.inducement_type,
          quickship_tp: this.getQuickShipTp()
        }
      })

      let curTime = Date.parse(new Date()) / 1000
      if (+payment_type == 2 && curTime >= expireCountdown) {
        this.$emit('toggle-expired-modal')
        return
      }
      if (this.cashPayMethod.includes(payment_method)) {
        this.$emit('cash-pay', { order: this.orderInfo, paymentMethod: payment_method })
        return
      }
      if(['routepay-konbini'].includes(payment_method)){
        this.$emit('handlePaynow', { page: 'orderDetails' })
        return
      }
      location.href = pay_code_url || pay_url
    },
    confirmPayNowEvt(options = {}, source) {
      let { payment_method } = this.orderInfo || {}
      let { confirmPayType: type } = options || {}
      let isIncludesType = ['PayNow', 'ViewCode'].includes(type)
      let daId = isIncludesType ? '1-19-1-69' : '1-19-1-71'

      if (daId) {
        let extraData = {}
        if (isIncludesType) {
          extraData = {
            bussiness_tp: this.orderBusiModel,
            buy_tp: orderLogic.getBuyTpInfo(this.orderInfo),
            order_no: this.orderInfo.billno,
            paybutton_location: source || 'below',
            inducement_type: this.analysisData.inducement_type,
            quickship_tp: this.getQuickShipTp()
          }
        } else {
          extraData = {
            abtest: this.detailNewFnAbt,
            order_busi_model: '',
            buy_tp: orderLogic.getBuyTpInfo(this.orderInfo),
          }
        }
        daEventCenter.triggerNotice({
          daId,
          extraData
        })
      }

      if (isIncludesType && this.orderResultAbtInfo.abTestConfig[type] === 'on') {
        this.toggleUnavailableModal()
        return
      }

      if (this.originPayMethod == 'cod' && payment_method == 'cod') {
        this.$emit('go-cod-pay')
        return
      }

      this.originPayMethod = payment_method
      this.$emit('handlePaynow', { page: 'orderDetails' })
    },
    // 封装公共的支付方法 end
    confirmDeliverAlterFun() {
      if (this.timeTips) {
        this.isShowTimeTipsDialog = true
        daEventCenter.triggerNotice({
          daId: '1-19-1-61',
          extraData: {
            billno: this.orderInfo.billno
          }
        })
        return
      }
      daEventCenter.triggerNotice({
        daId: '1-19-1-14',
        extraData: {
          order_id: this.orderInfo.billno
        }
      })
      let { confirmDeliveryBonusPoints: num, doublePoints } = this.orderInfo
      this.orderDeliveryForm.points = num
      this.orderDeliveryForm.confirmDeliveryPoints = num
      this.orderDeliveryForm.doublePoints = doublePoints
      this.orderDeliveryForm.billno = this.orderInfo.billno
      this.orderDeliveryForm.siteFrom = this.orderInfo.site_from
      this.orderDeliveryForm.isFreeTrial = this.orderInfo.marketing_type == 1
      this.orderDeliveryForm.uploadTryStatus = this.orderInfo.uploadTryStatus
      if (doublePoints) {
        num = `${parseInt(num + doublePoints)}(${num}+${doublePoints})`
        this.orderDeliveryForm.isDoublePoints = true
      } else {
        this.orderDeliveryForm.isDoublePoints = false
      }
      if (this.orderInfo.payment_method == 'cod') {
        this.checkCodOrderIsSupportReceipt(num)
      } else {
        this.checkNoCodOrderIsSupportReceipt(num)
      }
    },
    checkCommonEvt(num, type) {
      this.orderDeliveryForm.points = num
      this.confirmDeliverAlterTips =
        type == 1 ? this.language.SHEIN_KEY_PWA_17008 : this.language.SHEIN_KEY_PWA_17009
      this.showConfirmDeliveryTips = true
      daEventCenter.triggerNotice({
        daId: '1-19-1-15'
      })
    },
    // 检查非cod订单是否确认收货
    checkNoCodOrderIsSupportReceipt(num) {
      const subOrderStatus = this.orderInfo.subOrderStatus || []
      const excludeCodeList = [5, 7, 82, 75, 20, 126, 100, 127, 101, 18, 54]
      const notAllOrderHasDelivery = []
      let type = null
      subOrderStatus.forEach(item => {
        const goodsList = item.goodsList || []
        goodsList.forEach(good => {
          if (!excludeCodeList.includes(+good.goods_status)) {
            notAllOrderHasDelivery.push(good)
          }
        })
      })
      // 存在没有收货商品 提示文案有所不同
      if (notAllOrderHasDelivery.length) {
        type = 2
      } else {
        type = 1
      }
      this.checkCommonEvt(num, type)
    },
    errorCommonEvt: function (res) {
      this.showMsg({
        type: 'err',
        msg: res && res.code == 300542 ? res.tips : this.language.SHEIN_KEY_PWA_15948,
        timer: 2000
      })
      sa('send', {
        activity_name: 'expose_popup_confirm_error',
        activity_param: {}
      })
    },
    // 检查cod订单是否确认收货
    async checkCodOrderIsSupportReceipt(num) {
      let res = await schttp({
        method: 'POST',
        url: '/api/orders/base/checkOrderIsSupportReceipt/get',
        data: {
          billno: this.orderInfo.billno
        },
        schttp: { needLogin: true }
      })
      if (res.code == 0 && res.info) {
        if (res.info.confirmable_receipt) {
          this.checkCommonEvt(num, 1)
        } else {
          this.codOrderIsNotSupportReceiptTip = this.isSheinAPP
            ? this.language.SHEIN_KEY_PWA_16583.replace('[num]', num)
            : this.language.SHEIN_KEY_PWA_16929
          this.codOrderIsNotSupportReceipt = true
          sa('send', {
            activity_name: 'expose_popup_unconfirm',
            activity_param: {}
          })
        }
      } else {
        this.errorCommonEvt(res)
      }
    },
    confirmDeliverMessageEvt() {
      this.confirmDeliverMessage = false
      const orderDeliveryForm = this.orderDeliveryForm
      if (
        orderDeliveryForm.isCanComment &&
        (orderDeliveryForm.isCanComment === 1 || orderDeliveryForm.isCanComment === 3) &&
        !orderDeliveryForm.isFreeTrial
      ) {
        this.$router.push({
          path: `${this.locals.langPath}/user/orders/order_review?billno=${orderDeliveryForm.billno}&isRealTime=1`
        })
      }
    },
    codOrderIsNotSupportReceiptBiGA: function () {
      this.codOrderIsNotSupportReceipt = false
      sa('send', {
        activity_name: 'click_popup_unconfirm_ok',
        activity_param: {}
      })
    },
    handelTrackBtnClick() {
      if (this.orderResultAbtInfo.abTestConfig['Track'] === 'on') {
        this.toggleUnavailableModal()
        return
      }
      this.$router.push(`${this.locals.langPath}/user/orders/track/${this.orderInfo.billno}`)
    },
    async confirmDeliver() {
      const num = this.orderDeliveryForm.points || 0
      this.orderDeliveryPointCheckTips.showModal = false
      this.showConfirmDeliveryTips = false
      const isDoublePointsOrder = this.orderDeliveryForm.isDoublePoints
      const confirmPoints = this.orderDeliveryPointCheckTips.points
      const isFreeTrial = this.orderDeliveryForm.isFreeTrial
      const uploadTryStatus = this.orderDeliveryForm.uploadTryStatus
      this.changeRootStatus({
        loading: true
      })
      this.orderDeliveryForm.billno = this.orderInfo.billno
      this.orderDeliveryForm.isCanComment = this.orderInfo.isCanComment
      const isCanComment = this.orderDeliveryForm.isCanComment || 0
      let json = await schttp({
        method: 'POST',
        url: '/api/orders/base/complete/update',
        data: { billno: this.orderInfo.billno }
      })
      this.changeRootStatus({ loading: false })
      if (json.code == 0) {
        if (this.isPointCheckModle) {
          daEventCenter.triggerNotice({
            daId: '1-19-1-11',
            extraData: {
              order_id: this.orderInfo.billno,
              result: 1
            }
          })
        } else {
          daEventCenter.triggerNotice({
            daId: '1-19-1-16',
            extraData: {
              order_id: this.orderInfo.billno,
              result: 1
            }
          })
          // 没有触发积分熔断才需要跳转
          this.toDeliverySuccessPanel(this.orderInfo)
        }
        if (this.isSheinAPP) {
          if (this.orderDeliveryPointCheckTips.text != '') {
            const msg = confirmPoints
              ? this.language.SHEIN_KEY_PWA_18002.replace('{0}', confirmPoints)
              : this.language.SHEIN_KEY_PWA_17391
            this.$toast(msg)
          } else {
            if (isFreeTrial && uploadTryStatus && uploadTryStatus.status == '3') {
              this.confirmDeliverUpload = true
              daEventCenter.triggerNotice({
                daId: '1-19-1-35'
              })
            } else {
              if (num) {
                if (isCanComment && (isCanComment === 1 || isCanComment === 3) && !isFreeTrial) {
                  this.confirmDeliverMessageText = isDoublePointsOrder
                    ? this.language.SHEIN_KEY_PWA_17966.replace(
                      '{0}',
                      `<span class="red">${num}</span>`
                    )
                    : this.language.SHEIN_KEY_PWA_17966.replace('{0}', num)
                } else {
                  this.confirmDeliverMessageText = isDoublePointsOrder
                    ? this.language.SHEIN_KEY_PWA_17010.replace(
                      '[num]',
                      `<span class="red">${num}</span>`
                    )
                    : this.language.SHEIN_KEY_PWA_17010.replace('[num]', num)
                }
              } else {
                if (isCanComment && (isCanComment === 1 || isCanComment === 3) && !isFreeTrial) {
                  this.confirmDeliverMessageText = this.language.SHEIN_KEY_PWA_17938
                } else {
                  this.confirmDeliverMessageText = this.language.SHEIN_KEY_PWA_17391
                }
              }
              this.confirmDeliverMessage = true
            }
          }
        } else {
          if (isCanComment && (isCanComment === 1 || isCanComment === 3) && !isFreeTrial) {
            this.confirmDeliverMessage = true
            this.confirmDeliverMessageText = this.language.SHEIN_KEY_PWA_17938
          }
        }
        this.orderInfo.orderStatus = 5
        this.orderInfo.isCanConfirmDelivery = true
      } else {
        if (json.code == 300399) {
          this.showMsg({
            type: 'err',
            msg: this.language.SHEIN_KEY_PWA_14909
          })
        } else {
          this.showMsg({
            type: 'err',
            msg: this.language.SHEIN_KEY_PWA_15330
          })
        }
        if (this.isPointCheckModle) {
          daEventCenter.triggerNotice({
            daId: '1-19-1-11',
            extraData: {
              order_id: this.orderInfo.billno,
              result: 2
            }
          })
        } else {
          daEventCenter.triggerNotice({
            daId: '1-19-1-16',
            extraData: {
              order_id: this.orderInfo.billno,
              result: 2
            }
          })
        }
      }
    },

    async toDeliverySuccessPanel(order) {
      // eslint-disable-next-line @shein-aidc/abt/abt
      const { ConfirmDeliveryPage } = await abtservice.getUserAbtResult({
        posKeys: 'ConfirmDeliveryPage'
      })
      if (ConfirmDeliveryPage?.param && ConfirmDeliveryPage?.param != 'none') {
        this.$router.push({
          path: `${this.locals.langPath}/user/orders/state?type=delivery&billno=${order.billno}`
        })
      }
    },
    delOrder() {
      if (this.orderResultAbtInfo.abTestConfig['DeleteOrder'] === 'on') {
        this.toggleUnavailableModal()
        return
      }
      this.$emit('handleDeleteOrder', this.orderInfo.billno)
    },
    handleBtnListShow(elId) {
      this.$nextTick(() => {
        this.$refs[elId].style.display = this.$refs[elId].style.display == 'block' ? 'none' : 'block'
      })
    },
    handleBtnMoreItem(item) {
      item.el.click()
    },
    adjuctOrderListMore() {
      const listEl = $('.order-detail-footer').find('.order-detail-footer__btn:visible')
      if (this.orderInfo && listEl && listEl.length > 0) {
        listEl.each((i, n) => {
          const obj = $(n)
          let addClass = ''
          if (obj.position().top > 5) {
            obj.hide()
            if (obj.hasClass('opacity-item') || obj.hasClass('disabled-address_edit')) {
              addClass = 'opacity-item'
            }
            this.isShowBtnMore = true
            this.btnMoreList.push({
              el: obj,
              text: obj.html(),
              addClass: addClass
            })
          }
        })
      }
    },
    trackItemShow: function (orderInfo) {
      if (orderInfo.orderStatus == 4) {
        return true
      } else {
        const hasPackage =
          (orderInfo.oldOrderGoodsList &&
            orderInfo.oldOrderGoodsList.filter(item => {
              return item.reference_number
            })) ||
          []
        if (hasPackage.length) {
          return true
        }
        return false
      }
    },
    returnItemEvt (orderInfo = {}) {
      this.handleReturnItem({
        orderInfo: orderInfo,
        pageFrom: 'OrderDetail',
        position: this.defaultMoreOpen && !!this.isHideReturnItemBtn ? 'moreopen' : !!this.isHideReturnItemBtn ? 'more' : 'outside',
        isReturnOff: this.orderResultAbtInfo?.isReturnOff
      })
    },
    handleCancelOrder () {
      this.$emit('handleCancelOrder', this.orderInfo, 1)
    },
    cancelItemShow () {
      if (this.disabledCancelItem) {
        this.showFrontConditionText = this.htmlDecode(this.language.SHEIN_KEY_PWA_20121)
        this.showFrontConditionDialog = true
        return
      }
      if (this.overFrontCondition > 1) {
        daEventCenter.triggerNotice({
          daId: '1-19-1-72',
          extraData: {
            operation_from: 1
          }
        })
        this.showFrontConditionText = this.htmlDecode(this.language.SHEIN_KEY_PWA_20121)
        this.showFrontConditionDialog = true
        return
      }
      if (this.overCancelItemCondition) {
        this.showFrontConditionText = this.getB2B2CCondTips()
        this.showFrontConditionDialog = true
        return
      }
      daEventCenter.triggerNotice({
        daId: '1-19-1-39',
        extraData: {
          billno: this.orderInfo.billno
        }
      })
      if (this.isEnableCancelAbt) {
        this.toggleUnavailableModal()
        return
      }
      this.$emit('cancelItemShow')
    },
    getB2B2CCondTips() {
      let tempOrderGoodsList = this.orderInfo?.orderGoodsList?.flat(Infinity)
      let isCanRefundGoodsList = tempOrderGoodsList?.filter(
        item => item.isCodOrderCanPartCancel || item.isCommonOrderCanPartRefund
      )
      let hasFirstCond = isCanRefundGoodsList?.filter(item => item.pack_detail_status == 3)
      let hasSecondCond = isCanRefundGoodsList?.filter(item => item.pack_detail_status == 2)
      if (hasFirstCond?.length) {
        return this.language?.SHEIN_KEY_PWA_21409
      } else if (hasSecondCond?.length) {
        return this.language?.SHEIN_KEY_PWA_21410
      } else {
        return this.language?.SHEIN_KEY_PWA_21408
      }
    },
    async preCheckPointByConfirmOrder() {
      const { siteFrom = '', billno, site_from = '' } = this.orderInfo
      let res = await schttp({
        method: 'POST',
        url: '/api/orders/base/preCheckPointByConfirmReceive/query',
        data: {
          billno,
          site_from: siteFrom || site_from
        }
      })
      if (res.code == 0 && res.info) {
        daEventCenter.triggerNotice({
          daId: '1-19-1-16',
          extraData: {
            order_id: billno,
            result: ''
          }
        })
        if (!res.info.fuse_reason) {
          this.isPointCheckModle = false
          this.confirmDeliver()
        } else {
          if (res.info.max_available_point && res.info.max_available_point > 0) {
            this.orderDeliveryPointCheckTips.points = res.info.max_available_point
            this.orderDeliveryPointCheckTips.text = this.language.SHEIN_KEY_PWA_18000.replace(
              '{0}',
              this.orderDeliveryPointCheckTips.points
            )
          } else {
            this.orderDeliveryPointCheckTips.text = this.language.SHEIN_KEY_PWA_18001
          }
          this.showConfirmDeliveryTips = false
          this.orderDeliveryPointCheckTips.showModal = true
          this.isPointCheckModle = true
          daEventCenter.triggerNotice({
            daId: '1-19-1-10'
          })
        }
      } else {
        this.isPointCheckModle = false
        this.confirmDeliver()
      }
    },
    closeConfirmDeliver() {
      this.confirmDeliverMessage = false
    },
    async navToReview() {
      daEventCenter.triggerNotice({
        daId: '1-19-1-21'
      })
      if (this.orderResultAbtInfo.abTestConfig['Review'] === 'on') {
        this.toggleUnavailableModal()
        return
      }
      const { billno = '' } = this.orderInfo
      const verifyData = await this.handleRiskVerify(this.orderInfo)
      const { risk_decision, risk_id = '' } = verifyData?.info || {}
      const code = verifyData?.code
      if (code == 0 && risk_decision == 3610) {
        this.showRiskCommentTips = true
        daEventCenter.triggerNotice({
          daId: '1-19-1-144'
        })
        return
      }
      if (!this.IS_RW) {
        const riskData = {
          risk_id: code == 0 ? risk_id : '',
          billno: verifyData.billno,
          biz_id: verifyData.biz_id,
          sku_code_list: verifyData.sku_code_list,
          goods_id_list: verifyData.goods_id_list,
          goods_name_list: verifyData.goods_name_list,
          order_status: verifyData.order_status,
          blackbox: verifyData.blackbox,
          rateStatus: code == 0 && risk_decision == 3621 ? 0 : 1
        }
        console.log('verifyData', riskData)

        setLocalStorage({ key: 'risk_review_data', value: riskData })
      }
      this.$router.push({ path: `${this.locals.langPath}/user/orders/order_review?billno=${billno}` })
    },
    handleRiskVerify(order = {}) {
      if (this.IS_RW) return {}
      const { billno = '', orderGoodsList = [], orderStatus = '' } = order
      const sku_code_list = orderGoodsList.flat(Infinity).map(v => v?.product?.goods_sn || '')
      const goods_id_list = orderGoodsList.flat(Infinity).map(v => v.goods_id)
      const goods_name_list = orderGoodsList.flat(Infinity).map(v => v?.product?.goods_name || '')
      const biz_id = `${new Date().getTime()}_${billno}`
      const blackbox = window._fmOpt?.__blackbox || ''
      return new Promise((resolve) => {
        schttp({
          method: 'POST',
          url: '/api/risk/getReviewRisk/get',
          data: {
            billno,
            sku_code_list,
            goods_id_list,
            goods_name_list,
            blackbox,
            biz_id,
            order_status: orderStatus
          }
        }).then(res => {
          if(res !== null && typeof res === 'object') {
            res.biz_id = biz_id
            res.sku_code_list = sku_code_list
            res.goods_id_list = goods_id_list
            res.goods_name_list = goods_name_list
            res.order_status = orderStatus
            res.billno = billno
            res.blackbox = blackbox
          }
          resolve(res)
        }).catch(err => {
          resolve({})
          console.log('review risk error', err)
        })
      })
    },
    closeRiskCommentTips() {
      this.isRiskComment = true
      // 放在更多按钮时处理
      if (this.btnMoreList.length) {
        const reviewBtn = this.btnMoreList?.find(item => item?.el?.[0]?.name == 'review')
        if (reviewBtn) {
          reviewBtn.addClass = 'hide-points-tip'
        }
      }
    },
    navToMyReview: function (billno) {
      daEventCenter.triggerNotice({
        daId: '1-19-1-19'
      })
      this.$router.push({ path: `${this.locals.langPath}/user/orders/review_already?billno=${billno}` })
    },
    handlePointsTipShow: function (points) {
      this.$refs[points][0].style.display = 'none'
      this.$refs[points + '-arrow'][0].style.display = 'none'
    },
    // 暂时提高review优先级
    opeButtonSort(orderStatus) {
      let buttonSort = [
        'one_click_pay',
        'resume_shipment',
        'edit_address',
        'cancel_item',
        'view_invoices',
        'review',
        'my_review',
        'return_item',
        'track',
        'urge_shipment',
        'repurchase',
        'confirm_delivery',
        'pay_now',
        'verify',
        'cancel_order',
        'revoke',
        'upload_report'
      ]
      switch (orderStatus) {
        case 0:
        case 12:
          buttonSort = [
            'pay_now',
            'one_click_pay',
            'edit_address',
            'repurchase',
            'cancel_order',
            'confirm_delivery',
            'review',
            'my_review',
            'track',
            'upload_report',
            'cancel_item',
            'return_item',
            'verify',
            'revoke',
          ]
          break
        case 1:
          buttonSort = [
            'one_click_pay',
            'resume_shipment',
            'edit_address',
            'cancel_item',
            'view_invoices',
            'confirm_delivery',
            'review',
            'my_review',
            'track',
            'urge_shipment',
            'upload_report',
            'pay_now',
            'cancel_order',
            'return_item',
            'verify',
            'revoke',
            'repurchase'
          ]
          break
        case 3:
        case 8:
        case 16:
          buttonSort = [
            'one_click_pay',
            'edit_address',
            'view_invoices',
            'confirm_delivery',
            'review',
            'my_review',
            'track',
            'upload_report',
            'pay_now',
            'cancel_order',
            'return_item',
            'verify',
            'revoke',
            'cancel_item',
            'repurchase'
          ]
          break
        case 4:
          buttonSort = [
            'one_click_pay',
            'resume_shipment',
            'edit_address',
            'track',
            'urge_shipment',
            'exchange',
            'view_invoices',
            'revoke',
            'cancel_order',
            'revoke_item',
            'confirm_delivery',
            'upload_report',
            'review',
            'my_review',
            'pay_now',
            'return_item',
            'verify',
            'cancel_item',
            'repurchase'
          ]
          break
        case 5:
          buttonSort = [
            'one_click_pay',
            'resume_shipment',
            'review',
            'return_item',
            'my_review',
            'urgent_pickup',
            'view_invoices',
            'payment_receipt',
            'track',
            'repurchase',
            'confirm_delivery',
            'upload_report',
            'cancel_order',
            'revoke',
            'pay_now',
            'verify',
            'cancel_item'
          ]
          break
        case 6:
          buttonSort = [
            'one_click_pay',
            'resume_shipment',
            'edit_address',
            'revoke',
            'cancel_item',
            'revoke_item',
            'confirm_delivery',
            'review',
            'my_review',
            'return_item',
            'track',
            'urge_shipment',
            'upload_report',
            'cancel_order',
            'pay_now',
            'verify',
            'repurchase'
          ]
          break
        case 7:
          buttonSort = [
            'one_click_pay',
            'resume_shipment',
            'confirm_delivery',
            'review',
            'return_item',
            'my_review',
            'urgent_pickup',
            'view_invoices',
            'payment_receipt',
            'repurchase',
            'track',
            'upload_report',
            'revoke',
            'cancel_order',
            'pay_now',
            'verify',
            'cancel_item',
          ]
          break
        case 9:
          buttonSort = [
            'one_click_pay',
            'track',
            'confirm_delivery',
            'upload_report',
            'review',
            'my_review',
            'return_item',
            'revoke',
            'cancel_order',
            'pay_now',
            'verify',
            'cancel_item',
            'repurchase'
          ]
          break
        case 10:
          buttonSort = [
            'one_click_pay',
            'resume_shipment',
            'track',
            'urge_delivery',
            'edit_address',
            'confirm_delivery',
            'review',
            'upload_report',
            'return_item',
            'view_invoices',
            'payment_receipt',
            'my_review',
            'revoke',
            'cancel_order',
            'pay_now',
            'verify',
            'cancel_item',
            'repurchase'
          ]
          break
        case 11:
        case 14:
        case 15:
        case 17:
        case 19:
          buttonSort = [
            'one_click_pay',
            'return_item',
            'urgent_pickup',
            'track',
            'review',
            'my_review',
            'view_invoices',
            'payment_receipt',
            'confirm_delivery',
            'upload_report',
            'revoke',
            'cancel_order',
            'pay_now',
            'verify',
            'cancel_item',
            'repurchase'
          ]
          break
        case 13:
          buttonSort = [
            'verify',
            'one_click_pay',
            'edit_address',
            'repurchase',
            'cancel_order',
            'confirm_delivery',
            'review',
            'track',
            'upload_report',
            'my_review',
            'return_item',
            'revoke',
            'pay_now',
            'cancel_item',
          ]
          break
        case 18:
          buttonSort = [
            'one_click_pay',
            'resume_shipment',
            'confirm_delivery',
            'track',
            'review',
            'return_item',
            'upload_report',
            'view_invoices',
            'my_review',
            'verify',
            'cancel_order',
            'revoke',
            'pay_now',
            'cancel_item',
            'repurchase'
          ]
          break
        default:
          buttonSort = [
            'one_click_pay',
            'resume_shipment',
            'edit_address',
            'confirm_delivery',
            'review',
            'my_review',
            'return_item',
            'track',
            'upload_report',
            'view_invoices',
            'revoke',
            'cancel_order',
            'pay_now',
            'verify',
            'cancel_item',
            'repurchase'
          ]
      }

      return buttonSort
    },
    cancelPreCheckConfirmDeliver() {
      this.orderDeliveryPointCheckTips.showModal = false
      daEventCenter.triggerNotice({
        daId: '1-19-1-12'
      })
    },
    cancelConfirmDeliver() {
      this.showConfirmDeliveryTips = false
      daEventCenter.triggerNotice({
        daId: '1-19-1-17',
        extraData: {
          order_id: this.orderInfo.billno
        }
      })
    },
    toEditReport(data, source) {
      if (source == 'popup') {
        daEventCenter.triggerNotice({
          daId: '1-19-1-33',
          extraData: {
            click: '1'
          }
        })
      } else if (source == 'button') {
        daEventCenter.triggerNotice({
          daId: '1-19-1-36'
        })
      }
      this.confirmDeliverUpload = false
      if (data.status == 2) {
        // 置灰
        this.$toast(this.language.SHEIN_KEY_PWA_18092)
      } else if (data.status == 3) {
        // 展示并跳转
        let [orderData = {}] = this.orderInfo?.orderGoodsList?.flat(Infinity) || []
        const res = {
          goods_img: orderData.product?.goods_img,
          goods_name: orderData.product?.goods_name,
          goods_sn: orderData.product?.goods_sn,
          size_value: orderData.goods_attr,
          attr_language_value: this.filterAttrValue(orderData?.sku_sale_attr, 'attr_value_name')
        }
        this.$routerPush({
          name: 'post-report',
          params: {
            goods: {
              detail: {
                goods_img: res.goods_img,
                goods_name: res.goods_name,
                goods_sn: res.goods_sn
              },
              id: data.apply_id,
              size_value: res.size_value,
              attr_language_value: res.attr_language_value,
              goods_sn: res.goods_sn,
              billno: data.billno
            }
          },
          query: {
            from: 'order_detail'
          }
        })
      }
    },
    closeConfirmDeliverUpload(flag) {
      this.confirmDeliverUpload = false
      daEventCenter.triggerNotice({
        daId: flag == 'button' ? '1-19-1-33' : '1-19-1-34',
        extraData: {
          click: '2'
        }
      })
    },
    /**
     * @description 跳转查看发票
     * @date 2021-07-06 14:37
     * @param {string} billno - 订单号
     */
    navToViewInvoices(billno) {
      if(!this.isShowViewInvoices){
        return
      }
      daEventCenter.triggerNotice({
        daId: '1-19-1-49',
        extraData: {
          page_name: SaPageInfo.page_name
        }
      })
      if (getQueryString({ key: 'type' }) == 'view_invoice') {
        history.replaceState({}, '', location.href.split('?')[0])
      }
      billno && this.$router.push({ path: `${this.locals.langPath}/user/order_invoices/${billno}` })
    },
    showUrgeDelivery() {
      if (!this.showUrgeDeliveryBtn) {
        return
      }
      this.$refs.urgeDeliveryModalRef.showUrgeDelivery()
    },
    handelDeliveryCache() {
      let cache = getLocalStorage('hasUrgedDeliveryBill') || []
      const hasCurrentBill = cache?.find(item => item.billno == this.orderInfo.billno)
      if (!hasCurrentBill) {
        cache.push({
          billno: this.orderInfo.billno,
          hasUrged: true
        })
        setLocalStorage({
          key: 'hasUrgedDeliveryBill',
          value: cache
        })
      }
    },
    closeTipsModal() {
      this.showTipsModal = false
      this.handelDeliveryCache()
    },
    handleExchangeOperation() {
      if (this.exchangeInfo?.can_exchange == 2) {
        // 换货提示弹窗
        this.showExchangeTipsPop = true
        daEventCenter.triggerNotice({
          daId: '1-19-1-137',
          extraData: {
            order_no: this.orderInfo.billno,
            reason: this.exchangeInfo?.code
          }
        })
        return
      }
      this.$router.push({
        path: `${this.locals.langPath}/orders/selfExchange?billno=${this.orderInfo.billno}`
      })
    },
    getBtnMoreAnalysis(item) {
      let daId = item?.el?.[0]?.getAttribute('da-sa-id') || ''
      let reportData = item?.el?.[0]?.getAttribute('da-sa-param') || '{}'
      reportData = JSON.parse(reportData) ?? {}

      if (reportData.hasOwnProperty('is_promoshow')) {
        reportData.is_promoshow = 0
      }

      return daId ? {
        id: daId,
        data: reportData
      } : {}
    },
    showEditAddressDialogEvt (options = {}) {
      this.$emit('showEditAddressDialog', options)
    },
    urgeReturnEvt () {
      const buttonStatus = this.btnMoreList.some(item => item.text.includes(this.language.SHEIN_KEY_PWA_31578))
      const reportInfo = {
        daId: '1-19-1-148',
        bindData: {
          order_no: this.orderInfo.billno,
          button_status: buttonStatus ? 2 : 1,
          result: ''
        }
      }

      this.$refs.urgentPickUp?.initUrgeEvt({
        urgentList: this.urgentPickupList,
        billno: this.orderInfo.billno,
        reportInfo
      })
    },
    toPaymentReceipt() {
      this.showPaymentReceiptDialog = true
      daEventCenter.triggerNotice({
        daId: '1-19-1-151',
        extraData: {
          order_no: this.orderInfo.billno, 
          order_status: this.orderInfo.orderStatus
        }
      })
    },
  }
}
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.detail-footer-flex_layout{
  display: flex;
  align-items: center;
  .apple-pay-btn_box{
    .margin-l(.2rem);
  }
}
.detail-footer-text-right {
  .txt-r();
}
.detail-footer-z-fixedcheckout {
  .zindex-translatez(@zindex-fixedcheckout,translate3d(0,0,@tranlatez-fixedcheckout));
}
.detail-footer-btn-s {
  height: 0.8rem;
  line-height: 0.8rem;
  .font-dpr(24px);
}
.detail-footer-text-left {
  .txt-l();
}
.timeover-tips {
  text-align: center;
}
.confirm-deliver-alter-modal {
  strong > span {
    font-weight: bold;
    color: #e64545;
  }
  .red {
    font-weight: bold;
    color: #e64545;
  }
}
.footer-venmo-btn {
  background-color: #3d95ce;
  border-color: transparent;
  .venmo-btn-img {
    height: 15/75rem;
  }
}
.order-detail-footer {
  .one_click_pay-detail {
    position: relative;
    overflow: visible;
    .free_tips {
      position: absolute;
      right: -7.5/75rem;
      top: -37.5/75rem;
      color: @sui_color_white;
      background: @sui_color_success;
      padding: 15/75rem;
      .flexbox();
      justify-content: flex-start;
      align-items: center;
      line-height: 1;
      transform: scale(.6);
      transform-origin: right;
      .free_text {
        height: 30/75rem;
      }
      .tips-text-scale {
        display: inline-block;
        .font-dpr(24px);
        line-height: 14/12;
      }
      .icon {
        .font-dpr(24px) !important;
      }
      &:after {
        content: ' ';
        position: absolute;
        right: 0;
        bottom: -9/75rem;
        width: 0;
        height: 0;
        border-top: 10/75rem solid #16533a;
        border-right: 10/75rem solid transparent;
      }
    }
  }

  .cancel-order-link {
    border: none;
    font-weight: 400;
    padding: 0;
    color: @sui_color_link !important;
    background: transparent !important;
  }

  .repurchase-btn, .resume-shipment-btn {
    position: relative;
    overflow: initial;
  }
}
.exchange-tips {
  text-align: center;
}
</style>
<style lang="less" scoped>
.hide-points-tip {
  .comment-btn-op {
    display: none;
  }
}

.btn-more {
  color: #000;
}
</style>
