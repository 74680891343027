import { render, staticRenderFns } from "./DetailUserInfo.vue?vue&type=template&id=463ecbe6&scoped=true&"
import script from "./DetailUserInfo.vue?vue&type=script&lang=js&"
export * from "./DetailUserInfo.vue?vue&type=script&lang=js&"
import style0 from "./DetailUserInfo.vue?vue&type=style&index=0&id=463ecbe6&prod&lang=less&"
import style1 from "./DetailUserInfo.vue?vue&type=style&index=1&id=463ecbe6&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.8.1_ejs@2.7.2_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.86.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "463ecbe6",
  null
  
)

component.options.__file = "DetailUserInfo.vue"
export default component.exports